import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Articles from "../components/articles"

export default function Home({ data }) {
  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">ごんすなのブログ</h2>
      <div className="text-center md:text-left md:flex md:items-center md:justify-between">
        <StaticImage alt="gonsuna icon" src="../../static/chicken.png" className="w-32 mx-12 my-8" />
        <div className="text-left md:flex-1">
          日本のスタートアップで働くエンジニアです。どうでもいいことをつらつらと書いています。主に未来の自分に向けて書いています。
        </div>
      </div>
      <hr className="my-4 md:my-8" />
      <Articles mdxNodes={data.allMdx.nodes} />
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          title
          date(formatString: "yyyy.MM.DD")
          slug
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        id
        excerpt
      }
    }
  }
`
