import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Articles({ mdxNodes }) {
  return (
    <div>
    {
      mdxNodes.map((node) => {
        const image = getImage(node.frontmatter.hero_image)
        
        return (
          <article key={node.id} className="py-4 md:py-8 md:flex md:justify-between">
            <div>
              <h3 className="text-3xl font-semibold leading-snug">
                <Link to={`/blog/${node.frontmatter.slug}`}>
                  {node.frontmatter.title}
                </Link>
              </h3>
              <div className="mt-4 flex items-center">
                {node.frontmatter.tags.map((tag) => (
                  <div className="mr-2 text-blue-500 text-sm" key={`${node.id}-${tag}`}>
                    <Link to={`/tags/${tag}`}>
                      #{tag}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="mt-4 md:block hidden">{node.excerpt}</div>
              <div className="mt-4 text-sm">{node.frontmatter.date}</div>
              <div className="mt-4 text-blue-500 font-bold">
                <Link to={`/blog/${node.frontmatter.slug}`}>
                  Read more →    
                </Link>
              </div>
            </div>
            <div className="text-center mt-4 md:mt-0">
              <GatsbyImage image={image} alt={node.frontmatter.hero_image_alt} className="w-full object-cover md:w-52 h-52 md:ml-8" />
            </div>
          </article>
        )})
    }
    </div>
  )
}
